export function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export function getTodayDate() {
  const today = new Date();

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function toUrlEncoded(data, prefix) {
  if (typeof data !== 'object' || data === null) {
    throw new TypeError('Input must be a non-null object');
  }

  const formBody = [];

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const encodedKey = encodeURIComponent(key).replace(/%20/g, '+');
      const value = data[key];

      // JSON stringify nested objects
      const encodedValue = typeof value === 'object' && value !== null
        ? encodeURIComponent(JSON.stringify(value)).replace(/%20/g, '+')
        : encodeURIComponent(value).replace(/%20/g, '+');

      formBody.push(`${encodedKey}=${encodedValue}`);
    }
  }

  return formBody.join("&");
}

export function getCurrentFormattedTime() {
  const now = new Date();

  const pad = (num) => String(num).padStart(2, "0");

  const year = now.getFullYear();
  const month = pad(now.getMonth() + 1);
  const day = pad(now.getDate());

  const hours = pad(now.getHours());
  const minutes = pad(now.getMinutes());
  const seconds = pad(now.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

