import "./style.css";
import React from "react";

function CrossIcon({ handleCross, size = "large", className = "" }) {
  const sizeClasses = {
    small: "w-4 h-4",
    medium: "w-6 h-6",
    large: "w-8 h-8",
  };

  return (
    <div
      className={`cross-icon ${sizeClasses[size]} ${className}`}
      onClick={handleCross}
    >
      <div className="cross-line cross-line1"></div>
      <div className="cross-line cross-line2"></div>
    </div>
  );
}

export { CrossIcon };
