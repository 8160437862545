export const MARK_CHECK_IN_CHECK_OUT = {
  docstatus: 0,
  doctype: "Employee Checkin",
  name: "new-employee-checkin-1",
  __islocal: 1,
  __unsaved: 1,
  owner: "Administrator",
  log_type: "", // IN / OUT
  time: "", // 2024-06-11 16:51:28
  skip_auto_attendance: 0,
  attendance: null,
  employee_name: "", // Chunmai  Maheswari Madasu
  employee: "", // HR-EMP-00004
};

export const MARK_ATTENDANCE_DATA = {
  doc: {
    docstatus: 0,
    doctype: "Attendance",
    name: "new-attendance-2",
    __islocal: 1,
    __unsaved: 1,
    owner: "Administrator",
    naming_series: "HR-ATT-.YYYY.-",
    status: "Present",
    late_entry: 0,
    early_exit: 0,
    attendance_date: "",
    employee_name: "",
    company: "ARThink",
    department: "Engineering - ARThink",
    employee: "",
    shift: "Emp Shift",
  },
  action: "Submit",
};

export const APPLY_LEAVE = {
  docstatus: 0,
  doctype: "Leave Application",
  name: "new-leave-application-1",
  __islocal: 1,
  __unsaved: 1,
  owner: "Administrator",
  naming_series: "HR-LAP-.YYYY.-",
  follow_via_email: 1,
  posting_date: "2024-06-13", // Today date
  status: "Open",
  letter_head: "ARThink",
  company: "ARThink",
  department: "Human Resources - ARThink",
  "half_day_date":"",
  half_day: "",
};

export const APPLY_LEAVE_DAYS = {
  from_date: "", // 2024-06-20
  to_date: "", // 2024-06-20
  total_leave_days: 1, // 
}

export const APPLY_LEAVE_1_H = {
  custom_1_hour: 1, //
  custom_1_hour_day: "",
}

export const APPLY_LEAVE_2_H = {
  custom_2_hour: 1, //
  custom_2_hour_day: "",
}
