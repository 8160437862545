import React from "react";
import Modal from "@mui/material/Modal";
import "./styles.css";

function ConfirmationModal(props) {
  return (
    <Modal
      onClose={props.handleCloseModal}
      open={props.open}
      className="modal-container"
    >
      <div className="modal-wrapper">
        <h2 className="modal-title">{props.title}</h2>
        <p className={`${props.isSuccess ? "modal-success" : "modal-error"}`}>
          {props.message}
        </p>
      </div>
    </Modal>
  );
}

export { ConfirmationModal };
