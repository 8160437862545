// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 5%;
    width: 90%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .modal {
    position: relative;
    background: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1001;
    display: flex;
    flex-direction: column; 
    height: 92%; 
  }
  
  .modal-body {
    flex: 1 1; 
    overflow-y: auto; 
    padding: 10px 0;
    -ms-overflow-style: none;
    scrollbar-width: none; 
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-top: 10px; 
    border-top: 1px solid #eee;
  }
  
  .close-button {
    cursor: pointer;
    background: none;
    border: none;
    font-size: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Modal/styles.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,eAAe;IACf,MAAM;IACN,QAAQ;IACR,UAAU;IACV,YAAY;IACZ,8BAA8B,EAAE,gCAAgC;IAChE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,6BAA6B;IAC7B,oBAAoB;IACpB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,yCAAyC;IACzC,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,WAAW;EACb;;EAEA;IACE,SAAO;IACP,gBAAgB;IAChB,eAAe;IACf,wBAAwB;IACxB,qBAAqB;EACvB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,QAAQ;IACR,iBAAiB;IACjB,0BAA0B;EAC5B;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,eAAe;EACjB","sourcesContent":["/* styles.css */\n.modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 5%;\n    width: 90%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  .modal-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid #eee;\n    padding-bottom: 10px;\n    margin-bottom: 10px;\n  }\n  \n  .modal {\n    position: relative;\n    background: #fff;\n    padding: 10px 20px;\n    border-radius: 8px;\n    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);\n    z-index: 1001;\n    display: flex;\n    flex-direction: column; \n    height: 92%; \n  }\n  \n  .modal-body {\n    flex: 1; \n    overflow-y: auto; \n    padding: 10px 0;\n    -ms-overflow-style: none;\n    scrollbar-width: none; \n  }\n  \n  .modal-footer {\n    display: flex;\n    justify-content: flex-end;\n    gap: 5px;\n    padding-top: 10px; \n    border-top: 1px solid #eee;\n  }\n  \n  .close-button {\n    cursor: pointer;\n    background: none;\n    border: none;\n    font-size: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
