import { useState, useEffect, useCallback, useMemo } from "react";
import useApi from "./useApi";

const useGeolocation = () => {
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    error: null,
  });
  const [formattedAddress, setFormattedAddress] = useState("");
  const { loading, fetchData } = useApi();

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Earth radius in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon1 - lon2);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c * 1000; // Distance in meters
  };

  const handleSuccess = useCallback(
    async (position) => {
      const { latitude, longitude } = position.coords;
      const distance = location.latitude && location.longitude
      ? haversineDistance(latitude, longitude, location.latitude, location.longitude)
      : Infinity;

      if (distance && distance > 500) {
        setLocation({
          latitude,
          longitude,
          error: null,
        });
        try {
          const response = await fetchData(
            `google-reverse-geocoding/?lat=${latitude}&lan=${longitude}`,
            {},
            `https://backenddev.onfit.ai/api/`
          );
        setFormattedAddress(response.data[0].formatted_address);

        } catch (e) {
        }
      }
    },
    [location.latitude, location.longitude, fetchData]
  );
  const handleError = useCallback((error) => {
    let errorMessage;
    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorMessage = "User denied the request for Geolocation.";
        break;
      case error.POSITION_UNAVAILABLE:
        errorMessage = "Location information is unavailable.";
        break;
      case error.TIMEOUT:
        errorMessage = "The request to get user location timed out.";
        break;
      default:
        errorMessage = "An unknown error occurred.";
        break;
    }
    setLocation((prev) => ({
      ...prev,
      error: errorMessage,
    }));
    alert(
      `Error: ${errorMessage}. Please allow location access to use this app.`
    );
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!navigator.geolocation) {
      setLocation((prev) => ({
        ...prev,
        error: "Geolocation is not supported by your browser",
      }));
      alert(
        "Geolocation is not supported by your browser. The app cannot function without it."
      );
      return;
    }

    const geoOptions = {
      enableHighAccuracy: true,
      timeout: 30000, // Increased timeout duration to 30 seconds
      maximumAge: 0,
    };

    const watchId = navigator.geolocation.watchPosition(
      handleSuccess,
      handleError,
      geoOptions
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, [handleSuccess, handleError]);

  const memoizedAddress = useMemo(() => formattedAddress, [formattedAddress]);

  return { memoizedAddress, loading, ...location };
};

export { useGeolocation };
