// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.urbanist-font {
    font-family: 'Urbanist', sans-serif;
}
.employee-info-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;

}
.employee-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 70%;
    height: 70%;

}
/* .employee-img {
    width: 190px;
    height: 224px;

}

.employee-img img {
    width: 100%;
    height: 100%;
} */

.button-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
}
/* .upload-docs {
    position: absolute;
    right: 0;
} */`, "",{"version":3,"sources":["webpack://./src/layout/markEmployeeDetails/style.css"],"names":[],"mappings":"AAEA;IACI,mCAAmC;AACvC;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,8BAA8B;;AAElC;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,UAAU;IACV,WAAW;;AAEf;AACA;;;;;;;;;GASG;;AAEH;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,qBAAqB;IACrB,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;;;GAGG","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');\n\n.urbanist-font {\n    font-family: 'Urbanist', sans-serif;\n}\n.employee-info-container {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n    justify-content: space-between;\n\n}\n.employee-info {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: center;\n    width: 70%;\n    height: 70%;\n\n}\n/* .employee-img {\n    width: 190px;\n    height: 224px;\n\n}\n\n.employee-img img {\n    width: 100%;\n    height: 100%;\n} */\n\n.button-container {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n    align-items: flex-end;\n    justify-content: space-between;\n    margin-bottom: 20px;\n}\n/* .upload-docs {\n    position: absolute;\n    right: 0;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
