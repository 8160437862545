// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */

.error-text {
    color: #bd271e;;
    font-size: small;
    align-self: flex-start;
    letter-spacing: 0.05em;
    margin-top: 4px;
  }
  
  .border-error {
    border-color: #bd271e;;
  }
  
  .dropdown-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .dropdown-wrapper label {
    color: black;
    font-size: medium;
    margin-bottom: 4px;
  }
  
  .dropdown-wrapper select {
    outline: none;
    padding: 8px 12px;
    border-radius: 8px;
    height: 36px;
  }
  
  .dropdown-wrapper select:hover {
    transition: 0.5s ease;
    transform: scaleX(1.02);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/RDropdown/styles.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,cAAc;IACd,gBAAgB;IAChB,sBAAsB;IACtB,sBAAsB;IACtB,eAAe;EACjB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,qBAAqB;IACrB,uBAAuB;EACzB","sourcesContent":["/* styles.css */\n\n.error-text {\n    color: #bd271e;;\n    font-size: small;\n    align-self: flex-start;\n    letter-spacing: 0.05em;\n    margin-top: 4px;\n  }\n  \n  .border-error {\n    border-color: #bd271e;;\n  }\n  \n  .dropdown-wrapper {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .dropdown-wrapper label {\n    color: black;\n    font-size: medium;\n    margin-bottom: 4px;\n  }\n  \n  .dropdown-wrapper select {\n    outline: none;\n    padding: 8px 12px;\n    border-radius: 8px;\n    height: 36px;\n  }\n  \n  .dropdown-wrapper select:hover {\n    transition: 0.5s ease;\n    transform: scaleX(1.02);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
