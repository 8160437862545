// RDropdown.js
import React from "react";
import "./styles.css";

const RDropdown = (props) => {
  const {
    label,
    value,
    error,
    onChange,
    options = [],
    placeholder = "Select an option",
    name,
    id,
    dataTestId,
    required = false,
    className,
    wrapperClassName,
  } = props;

  return (
    <>
      <div
        className={`${wrapperClassName} dropdown-wrapper`}
        data-testid={dataTestId}
      >
        <label htmlFor={name}>{label}</label>
        <select
          required={required}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          className={`${className} ${Boolean(error) ? "border-error" : ""}`}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {error && <span className="error-text">{error}</span>}
      </div>
    </>
  );
};

export { RDropdown };
