// Login.js
import React, { useState } from "react";
import "./Login.css"; // Import your CSS file
import { useNavigate } from "react-router-dom";
import Azure from "../../assets/azure.png";
import useApi from "../../hooks/useApi";
import { RInput } from "../../components/RInput";
import Spinner from "../../components/Spinner";
import useSessionStorage from "../../hooks/useSessionStorage";
import { emailValidator, passwordValidator } from "../../constants/validators";

const Login = () => {
  const navigate = useNavigate();
  const [, saveToken] = useSessionStorage();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const { loading, postData } = useApi("token");

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "email") {
      handleValidation(value, emailValidator, name);
    } else if (name === "password") {
      handleValidation(value, passwordValidator, name);
    }
  };

  const handleValidation = (value, validator, fieldName) => {
    const errorMessage = validator(value)
      ? ""
      : `Please enter a valid ${fieldName}`;
    setErrors((prev) => ({
      ...prev,
      [fieldName]: errorMessage,
    }));
  };

  const validateSubmit = () => {
    const validatedInputs = Object.entries(formData).map((item) => {
      handleValidation(
        item[1],
        item[0] === "email" ? emailValidator : passwordValidator,
        item[0]
      );
      return item[0] === "email"
        ? emailValidator(item[1])
        : passwordValidator(item[1]);
    });

    const isValid = !validatedInputs.some((item) => item === false);

    if (isValid) {
      handleSubmit(formData);
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await postData(
        "auth/login",
        {
          ...formData,
        },
        false,
        {
          "Content-Type": "application/json",
        }
      );
      if (res && res?.token) saveToken(res.token ?? "", "token");
      navigate("/verified");
    } catch (e) {
      console.error("e", e);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      // Redirect the user to the Google login endpoint on the backend
      window.location.href = "http://127.0.0.1:5000/auth/google_login";
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAzureLogin = async () => {
    try {
      // Redirect the user to the Google login endpoint on the backend
      window.location.href =
        "https://fbcd-183-82-103-177.ngrok-free.app/auth/azure_login";
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="login-container">
      {loading && <Spinner />}
      <h2 className="heading">Login</h2>
      <RInput
        name="email"
        label={"Enter email id"}
        onChange={handleInputChange}
        error={errors.email}
        placeholder="Enter Email"
        value={formData.email}
        wrapperClassName="input-box"
        className="input-field"
        type="text"
      />
      <RInput
        name="password"
        label={"Enter password "}
        onChange={handleInputChange}
        error={errors.password}
        placeholder="Enter password"
        value={formData.password}
        wrapperClassName="input-box"
        className="input-field"
        type="password"
        isPassword={true}
      />
      <button onClick={validateSubmit}>Login</button>

      <div className=" ">
        <div className="or-container">
          <span>OR</span>
        </div>
        <div className="flex">
          <button onClick={handleGoogleLogin} class="gsi-material-button">
            <div className="gsi-material-button-state"></div>
            <div className="gsi-material-button-content-wrapper">
              <div className="gsi-material-button-icon">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  style={{ display: "block" }}
                >
                  <path
                    fill="#EA4335"
                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                  ></path>
                  <path
                    fill="#4285F4"
                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                  ></path>
                  <path
                    fill="#FBBC05"
                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                  ></path>
                  <path
                    fill="#34A853"
                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                  ></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
              </div>
              <span className="gsi-material-button-contents">Sign in</span>
              <span style={{ display: "none" }}>Sign in with Google</span>
            </div>
          </button>
          <button className="azure-btn" onClick={handleAzureLogin}>
            <img alt="azure" src={Azure} className="azure-logo" /> Login with Azure
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
