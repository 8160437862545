import React from "react";
import "./Spinner.css";

export default function Spinner() {
  return (
    <div className="spinner-container">
      <div className="background"></div>
      <div className="square-spinner"></div>
      <div className="typing-spinner">Loading...</div>
    </div>
  );
}
