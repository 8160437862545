import React from "react";
import CameraComponent from "../../components/Camera";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { DataURIToBlob } from "../../utils/functions";

import "./styles.css";
function SaveEncoding() {
  const navigate = useNavigate();
  const { loading, postData } = useApi();
  const [showModal, setShowModal] = React.useState(false);
  const [modalText, setModalText] = React.useState({
    title: "Attendence",
    message: "Attendence marked",
    isSuccess: true,
  });

  const handleFaceRecognize = async (imgBase64) => {
    const file = DataURIToBlob(imgBase64);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await postData("upload_image", formData, true);
      if (res && res?.data === "done") {
        setShowModal(true);
        setModalText((prev) => ({
          ...prev,
          message: `Face uploaded successfully!!!`,
          isSuccess: true,
        }));
        setTimeout(
          () => (
            // eslint-disable-next-line
            setShowModal(false),
            sessionStorage.removeItem("token"),
            navigate("/")
          ),
          5000
        );
      }
    } catch (err) {
      setShowModal(true);
      setModalText((prev) => ({
        ...prev,
        title: "Opps",
        message: `Something went wrong`,
        isSuccess: false,
      }));
      setTimeout(() => setShowModal(false), 5000);
    }
  };
  return (
    <div className="save-encoding-wrapper">
      <h1 className="heading">Register Face</h1>
      <CameraComponent
        loading={loading}
        handleFaceRecognize={handleFaceRecognize}
        height={"82dvh"}
      />
      {showModal && (
        <ConfirmationModal
          open={showModal}
          title={modalText.title}
          message={modalText.message}
          isSuccess={modalText.isSuccess}
          handleCloseModal={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

export { SaveEncoding };
