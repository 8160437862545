/* eslint-disable */
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import SignUp from "./pages/signup";
import { SaveEncoding } from "./pages/saveEncoding";
import { VerifiedUser } from "./pages/verifiedUser";
import { ActionHome } from "./pages/actionHome";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ActionHome />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verified" element={<VerifiedUser />} />
        <Route path="/save-encoding" element={<SaveEncoding />} />
        <Route path="/check-in" element={<Home />} />
        <Route path="/check-out" element={<Home />} />
        <Route path="/leave" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
