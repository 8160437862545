// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cross-icon {
    position: relative;
    cursor: pointer;
  }
  
  .cross-line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: black;
    top: 50%;
    left: 0;
  }
  
  .cross-line1 {
    transform: rotate(45deg);
  }
  
  .cross-line2 {
    transform: rotate(-45deg);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/crossIcon/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,uBAAuB;IACvB,QAAQ;IACR,OAAO;EACT;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".cross-icon {\n    position: relative;\n    cursor: pointer;\n  }\n  \n  .cross-line {\n    position: absolute;\n    width: 100%;\n    height: 2px;\n    background-color: black;\n    top: 50%;\n    left: 0;\n  }\n  \n  .cross-line1 {\n    transform: rotate(45deg);\n  }\n  \n  .cross-line2 {\n    transform: rotate(-45deg);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
