/* eslint-disable */
import React from "react";
import { useNavigate } from "react-router-dom";

const ActionHome = () => {
  const navigate = useNavigate();

  const domain = window.location.hostname;
  const subDomain = domain.split(".")[0];
  const baseUrl = "https://empledostrgacnt.blob.core.windows.net/";
  const defaultImageUrl =
    "https://cel.net.in/wp-content/uploads/2022/10/C_L_.jpg";

  const effectiveSubDomain = subDomain === "localhost" ? "video" : subDomain;
  const imageUrl = effectiveSubDomain
    ? `${baseUrl}${effectiveSubDomain}/logo.png`
    : defaultImageUrl;

  return (
    <div className="signup-container">
      <div className="w-[150px] h-[183px] rounded-sm flex flex-row">
        <img src={imageUrl} alt="Logo" />
      </div>
      <button onClick={() => navigate("check-in")}>Check In</button>
      <button onClick={() => navigate("check-out")}>Check Out</button>
      <button onClick={() => navigate("leave")}>Apply Leave</button>
    </div>
  );
};

export { ActionHome };
