import { useState } from "react";
import { BASE_URL } from "../constants/endpoints";
import useSessionStorage from "./useSessionStorage";
const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [token, , clearToken] = useSessionStorage("token");

  const postData = async (
    url,
    data,
    isFormData = false,
    headers = {},
    BASE_URL_NEW = BASE_URL
  ) => {
    setLoading(true);
    setError(null);
    let authHeader = { ...headers };
    try {
      if (token) {
        authHeader = { ...headers, Authorization: `Bearer ${token}` };
      }
      const response = await fetch(`${BASE_URL_NEW}${url}`, {
        method: "POST",

        headers: {
          ...authHeader,
        },
        body: isFormData ? data : JSON.stringify(data),
      });
      const result = (await response.json()) || {};
      if (response.status === 401) {
        clearToken("token");
        throw new Error(result?.message || "Something went wrong");
      }
      if (!response.ok && response.status !== 417) {
        throw new Error(result || "Something went wrong");
      }

      return result;
    } catch (err) {
      setError(err ?? "Something went wrong");
      throw new Error(err ?? "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (url, headers = {}, BASE_URL_NEW = BASE_URL) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${BASE_URL_NEW}${url}`, {
        method: "GET",
        headers,
      });
      const result = (await response?.json()) || {};

      if (!response.ok) {
        throw new Error(result.message || "Something went wrong");
      }

      return result;
    } catch (err) {
      setError(err?.message ?? "Something went wrong");
      throw new Error(err?.message ?? "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, postData, fetchData };
};

export default useApi;
