// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.modal-wrapper {
  background-color: #b3cdd1;
  background-image: linear-gradient(315deg, #b3cdd1 0%, #9fa4c4 74%);
  max-width: 80%;
  padding: 24px;
  border-radius: 24px;
  outline: none;
}
.modal-error {
  color: #BA1A1A;
  background: #FFDAD6;
  font-weight: bold;
  text-align: center;
  word-wrap: break-word;
  padding: 8px;
  margin: 0px;
  border-radius: 4px;
}

.modal-success {
  color: #90427a;
  background: #FFD8EE;
  font-weight: bold;
  text-align: center;
  word-wrap: break-word;
  padding: 8px;
  margin: 0px;
  border-radius: 4px;
}

.modal-title {
  color: black;
  border-bottom: 1px solid white;
  line-height: 40px;
  margin-top: 0px;
  word-wrap: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/components/ConfirmationModal/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,kEAAkE;EAClE,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,cAAc;EACd,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,8BAA8B;EAC9B,iBAAiB;EACjB,eAAe;EACf,qBAAqB;AACvB","sourcesContent":[".modal-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  outline: none;\n}\n\n.modal-wrapper {\n  background-color: #b3cdd1;\n  background-image: linear-gradient(315deg, #b3cdd1 0%, #9fa4c4 74%);\n  max-width: 80%;\n  padding: 24px;\n  border-radius: 24px;\n  outline: none;\n}\n.modal-error {\n  color: #BA1A1A;\n  background: #FFDAD6;\n  font-weight: bold;\n  text-align: center;\n  word-wrap: break-word;\n  padding: 8px;\n  margin: 0px;\n  border-radius: 4px;\n}\n\n.modal-success {\n  color: #90427a;\n  background: #FFD8EE;\n  font-weight: bold;\n  text-align: center;\n  word-wrap: break-word;\n  padding: 8px;\n  margin: 0px;\n  border-radius: 4px;\n}\n\n.modal-title {\n  color: black;\n  border-bottom: 1px solid white;\n  line-height: 40px;\n  margin-top: 0px;\n  word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
