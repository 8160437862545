import React from "react";
import { Link, useLocation } from 'react-router-dom';
import useSessionStorage from "../../hooks/useSessionStorage";

function VerifiedUser() {
    const location = useLocation();
    const [token] = useSessionStorage("token");
    const getName = () => {
        const searchParams = new URLSearchParams(location.search);
        const nameParam = searchParams.get('name');
      
        // Check if the name parameter exists and log its value
        if (nameParam) {
          return nameParam
          // Output: Sumit (or the value of the name parameter)
        }
        return "your"
      
    }
return (
    <div>
        <h1>{`Congrates ${getName()} attendence is marked`}</h1>
     {token&& <Link to="/save-encoding">
       <button> Upload Image</button>
       </Link>}
    
    </div>
)
}

export {VerifiedUser}