import React, { useState } from "react";
import "./Signup.css";
import useApi from "../../hooks/useApi";
import { RInput } from "../../components/RInput";
import { RDropdown } from "../../components/RDropdown";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";
import {
  emailValidator,
  nameValidator,
  passwordValidator,
  empidValidator,
  phoneValidator,
} from "../../constants/validators";
import {ConfirmationModal} from "../../components/ConfirmationModal"
import useSessionStorage from "../../hooks/useSessionStorage";

const HR_CODE = "HR-EMP-000";
const INITIAL_ERROR = {
  email: "",
  password: "",
  name: "",
  visitorType: "",
  empid: "",
  phoneNumber: "",
  visiting: "",
  checkIn: "",
  checkOut: "",
};

const getCurrentTime = () => {
  const now = new Date();
  return now.toTimeString().substring(0, 5);
};

const getDefaultCheckoutTime = () => {
  const now = new Date();
  now.setHours(18, 0, 0); // Set to 6 PM
  return now.toTimeString().substring(0, 5);
};

const indianNamesOptions = [
  { value: "Aarav", label: "Aarav" },
  { value: "Vivaan", label: "Vivaan" },
  { value: "Aadhya", label: "Aadhya" },
  { value: "Diya", label: "Diya" },
];

const inputFields = {
  common: [
    {
      name: "name",
      label: "Enter your name",
      type: "text",
      validator: nameValidator,
    },
    {
      name: "email",
      label: "Enter your email id",
      type: "text",
      validator: emailValidator,
    },
    {
      name: "phoneNumber",
      label: "Enter your phone number",
      type: "text",
      validator: phoneValidator,
    },
  ],
  employee: [
    {
      name: "empid",
      label: "Enter your employee ID",
      type: "text",
      validator: empidValidator,
      defaultValue: HR_CODE,
    },
    {
      name: "password",
      label: "Enter password",
      type: "password",
      validator: passwordValidator,
    },
  ],
  visitor: [
    {
      name: "visiting",
      label: "Visitor's Name",
      type: "dropdown",
      options: indianNamesOptions,
      validator: nameValidator,
    },
    {
      name: "checkIn",
      label: "Check In",
      type: "time",
      defaultValue: getCurrentTime(),
    },
    {
      name: "checkOut",
      label: "Check Out",
      type: "time",
      defaultValue: getDefaultCheckoutTime(),
    },
  ],
};

const SignUp = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [modalText, setModalText] = React.useState({
    title: "Visitor",
    message: "Visitor data saved successfully",
    isSuccess: true,
  });
  const [fakeLoader, setLoading] = React.useState(false);
  const [, saveToken] = useSessionStorage();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    visitorType: "employee",
    empid: HR_CODE,
    phoneNumber: "",
    visiting: "",
    checkIn: getCurrentTime(),
    checkOut: getDefaultCheckoutTime(),
  });

  const [errors, setErrors] = useState({ ...INITIAL_ERROR });
  const { loading, error, postData } = useApi("token");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    const fieldConfig =
      inputFields.common.find((field) => field.name === name) ||
      inputFields[formData.visitorType].find((field) => field.name === name);

    if (fieldConfig?.validator) {
      handleValidation(value, fieldConfig.validator, name);
    }
  };

  const handleValidation = (value, validator, fieldName) => {
    const errorMessage = validator
      ? validator(value)
        ? ""
        : `Please enter a valid ${fieldName}`
      : "";
    setErrors((prev) => ({
      ...prev,
      [fieldName]: errorMessage,
    }));
  };

  const validateSubmit = () => {
    const validatedInputs = Object.entries(formData).map(([key, value]) => {
      const fieldConfig =
        inputFields.common.find((field) => field.name === key) ||
        inputFields[formData.visitorType].find((field) => field.name === key);

      if (fieldConfig?.validator) {
        handleValidation(value, fieldConfig.validator, key);
        return fieldConfig.validator(value);
      }
      return true;
    });

    const isValid = !validatedInputs.some((item) => item === false);

    if (isValid && formData.visitorType === "employee") {
      handleSubmit();
    } else  if(isValid && formData.visitorType === "visitor"){
      handleFakeApiCall()
    }
  };

  const handleFakeApiCall = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
      setShowModal(true);
      setModalText({
        title: `${formData.name} your visitor`,
        message: `Data saved successfully`,
        isSuccess: true,
      })
    }, 1000);
  }
  React.useEffect(() => {
    if(showModal) {
      setTimeout(() => navigate("/"), 5000);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const handleSubmit = async () => {
    try {
      const res = await postData(
        "auth/signup",
        {
          email: formData.email,
          password: formData.password,
          name: formData.name,
          empid: formData?.empid || "visitor",
        },
        false,
        {
          "Content-Type": "application/json",
        }
      );
      if (res && res?.token) saveToken(res.token ?? "", "token");
      navigate("/save-encoding");
    } catch (e) {
      console.error("e", e);
    }
  };

  const renderInputs = (fields) =>
    fields.map(({ name, label, type, defaultValue, options }) => {
      if (type === "dropdown") {
        return (
          <RDropdown
            key={name}
            name={name}
            label={label}
            onChange={handleInputChange}
            error={errors[name]}
            options={options}
            value={formData[name] ?? ""}
            wrapperClassName="input-box"
            className="input-field"
          />
        );
      }
      return (
        <RInput
          key={name}
          name={name}
          label={label}
          onChange={handleInputChange}
          error={errors[name]}
          placeholder={`Enter ${label.toLowerCase()}`}
          value={formData[name] ?? defaultValue ?? ""}
          wrapperClassName="input-box"
          className="input-field"
          type={type}
        />
      );
    });


  return (
    <div className="signup-container">
      {(loading || fakeLoader) && <Spinner />}
      <h2 className="heading">Register with ARThink</h2>
      {error && <p>{error}</p>}
      <div className="toggle-option-wrapper">
        <div>
          <input
            onChange={handleInputChange}
            id="visitor"
            name="visitorType"
            type="radio"
            value="visitor"
            checked={formData.visitorType === "visitor"}
          />
          <label className="cursor-pointer" htmlFor="visitor">
            Visitor
          </label>
        </div>

        <div>
          <input
            onChange={handleInputChange}
            id="employee"
            name="visitorType"
            type="radio"
            value="employee"
            checked={formData.visitorType === "employee"}
          />
          <label className="cursor-pointer" htmlFor="employee">
            Employee
          </label>
        </div>
      </div>
      {renderInputs(inputFields.common)}
      {renderInputs(inputFields[formData.visitorType])}
      {showModal && (
        <ConfirmationModal
          open={showModal}
          title={modalText.title}
          message={modalText.message}
          isSuccess={modalText.isSuccess}
          handleCloseModal={() => setShowModal(false)}
        />
      )}
      <button onClick={validateSubmit}>Submit</button>
    </div>
  );
};

export default SignUp;
