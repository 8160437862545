// Modal.js
import React from 'react';
import './styles.css';

const Modal = ({ show, onClose, title, children, onClickFooter, isFooterVisible, footerButtonName, isFooterDisabled = false }) => {
  if (!show) {
    return null;
  }
  const renderedTitle = (title === 'check-in') ? 'CHECKIN' : (title === 'check-out') ? 'CHECKOUT' : title;
  const handleOutsideClick = (e) => {
    e.stopPropagation()
  }
  return (
    <div className="modal-overlay" >
      <div className="modal Msmd:w-[90%] msm:w-[90%] msmd:w-[70%] " onClick={handleOutsideClick}>
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-footer">
          <button style={{ color: '#0077CC', background: '#FFFFFF', fontSize: '16px', height: '36px', margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={onClose}>Cancel</button>
          <button style={{ background: '#0077CC', fontSize: '16px', height: '36px', margin: 0, display:'flex', alignItems:'center',justifyContent:'center'}} disabled={isFooterDisabled} onClick={onClickFooter}>{renderedTitle}</button>
        </div>
      </div>
    </div>
  );
};

export { Modal };
