import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const useSessionStorage = (key = 'token') => {
  const navigate = useNavigate();
  const [token, setToken] = useState(() => {
    // Initial value from session storage if available
    const storedToken = sessionStorage.getItem(key);
    return storedToken ? JSON.parse(storedToken) : null;
  });

  useEffect(() => {
    // Update session storage whenever token changes
    if (token) {
      sessionStorage.setItem(key, JSON.stringify(token));
    } else {
      sessionStorage.removeItem(key);
    }
  }, [key, token]);

  const saveToken = (newToken) => {
    sessionStorage.setItem(key, JSON.stringify(newToken));
    setToken(newToken);
  };

  const clearToken = () => {
    sessionStorage.removeItem(key);
    navigate("/login")
    setToken(null);
  };

  return [token, saveToken, clearToken];
};

export default useSessionStorage;
