import React, { useRef } from "react";
import { Modal } from "../../components/Modal";
import { CrossIcon } from "../../components/crossIcon";
import { getTodayDate, DataURIToBlob } from "../../utils/functions";
import Spinner from "../../components/Spinner";
import "./style.css";
import {
  APPLY_LEAVE_DAYS,
  APPLY_LEAVE_1_H,
  APPLY_LEAVE_2_H,
  APPLY_LEAVE,
} from "../../constants/constants";
import { useLocation } from "react-router-dom";
import useApi from "../../hooks/useApi";
import uploadGif from "../../assets/upload.gif";

/**
 * Employee details props:
 * @param {
 *   res.docs[0].name - 'HR-EMP-00027'
 *   res.docs[0].designation - 'Software Engineer'
 *   res.docs[0].department - 'Engineering - ARThink'
 *   res.docs[0].first_name - 'Sana Tabassum'
 *   res.docs[0].permanent_address - 'Block No. 149 Road No. 826 Clifton No.926 Kasur'
 * } props
 * @returns JSX Element
 */
const BASE_URL_UPLOAD_PHOTO = process.env.REACT_APP_FILE_UPLOAD;

const today = new Date();
// Calculate tomorrow's date
const todayDate = today.toISOString().split("T")[0];
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

// Format tomorrow's date to YYYY-MM-DD
const minDate = tomorrow.toISOString().split("T")[0];

const MarkEmployeeDetails = (props) => {
  const { postData, fetchData } = useApi();
  const baseLeaveInfo = {
    employee_name: props?.data?.docs[0]?.employee_name,
    employee: props?.data?.docs[0]?.employee_number,
    leave_approver:
      props?.data?.docs[0]?.leave_approver ?? "mohitrakhade20@gmail.com",
    leave_approver_name:
      props?.data?.docs[0]?.leave_approver ?? "mohitrakhade20@gmail.com",
    department: props?.data?.docs[0]?.department,
    leave_type: "Casual Leave",
    // leave_balance:  props?.data?.docs[0]?.leave_balance,
    description: "Leave",
    posting_date: getTodayDate(),
  };
  const [applyLeave, setApplyLeave] = React.useState(false);
  const [selectFullDate, setSelectFullDate] = React.useState("");
  const [leaveInfo, setLeaveInfo] = React.useState({ ...baseLeaveInfo });
  const [errors, setErrors] = React.useState({});
  const [isDocument, setIsDocument] = React.useState(false);
  const [customDocument, setCustomDocument] = React.useState(""); // custom_document
  const [fileName, setFileName] = React.useState("");
  const [formattedAddress, setFormattedAddress] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loadingData, setLoadingData] = React.useState(false);

  const fileInputRef = useRef(null);
  const useCurrentPath = () => {
    const location = useLocation();
    return location.pathname;
  };
  const query = useCurrentPath();

  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }
  React.useEffect(() => {
    if (query.slice(1) === "leave") {
      setApplyLeave(true);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRelocate = async () => {
    setLoadingData(true);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        if (latitude && longitude) {
          try {
            const response = await fetchData(
              `google-reverse-geocoding/?lat=${latitude}&lan=${longitude}`,
              {},
              `https://backenddev.onfit.ai/api/`
            );
            setFormattedAddress(response.data[0].formatted_address);
            setErrorMessage("");
          } catch (error) {
            setErrorMessage("Failed to fetch address data.");
          } finally {
            setLoadingData(false);
          }
        } else {
          setErrorMessage("Latitude and longitude not found.");
          setLoadingData(false);
        }
      },
      (error) => {
        console.error("Geolocation error:", error);
        setLoadingData(false);
      }
    );
  };

  React.useEffect(() => {
    if (leaveInfo?.from_date && leaveInfo?.to_date) {
      const fromDate = new Date(leaveInfo.from_date);
      const toDate = new Date(leaveInfo.to_date);

      // Check if from_date is later than to_date
      if (fromDate > toDate) {
        setLeaveInfo((prevInfo) => ({
          ...prevInfo,
          to_date: prevInfo.from_date,
        }));
      }

      // Calculate the difference in days
      const diffTime = Math.abs(toDate - fromDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      // Set isDocument to true if the difference is more than 3 days
      setIsDocument(diffDays > 2);
    }
  }, [selectFullDate, leaveInfo?.from_date, leaveInfo?.to_date]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setLeaveInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handlePhotoUpload = async () => {
    const formData = new FormData();
    formData.append("file", customDocument);
    try {
      const response = await postData(
        "api/empledo-upload-file/",
        formData,
        true,
        {},
        BASE_URL_UPLOAD_PHOTO
      );
      return response?.data?.image_url ?? "";
    } catch (e) {
      console.error("Image upload error");
    }
  };

  const handleClickFooter = async () => {
    // Validate all fields
    let valid = true;
    let newErrors = {};
    let leaveType;
    switch (selectFullDate) {
      case "1h":
        leaveType = "1 hour early";
        break;
      case "2h":
        leaveType = "2 hour early";
        break;
      case "fullDay":
        leaveType = "Full Day";
        break;
      default:
        leaveType = "leave";
        break;
    }
    let custom_document;
    if (isDocument) {
      if (!customDocument) {
        valid = false;
        newErrors.custom_document = "This field is required.";
      } else {
        custom_document = await handlePhotoUpload();
      }
    }

    Object.keys(leaveInfo).forEach((key) => {
      if (!leaveInfo[key]) {
        valid = false;
        newErrors[key] = "This field is required.";
      }
    });

    if (!valid) {
      console.log("New errors", newErrors);
      setErrors(newErrors);
    } else {
      let dummyData = {
        from_date: leaveInfo?.from_date,
        to_date: leaveInfo?.to_date,
        total_leave_days: leaveInfo.total_leave_days,
      };
      if (selectFullDate !== "fullDay") {
        dummyData.from_date =
          leaveInfo?.custom_1_hour_day ?? leaveInfo?.custom_2_hour_days;
        dummyData.to_date =
          leaveInfo?.custom_1_hour_day ?? leaveInfo?.custom_2_hour_days;
        dummyData.total_leave_days = leaveInfo?.total_leave_days ?? 1;
      }
      if (custom_document) {
        props.handleLeaveFromEmployee(
          {
            ...APPLY_LEAVE,
            ...leaveInfo,
            ...dummyData,
            custom_document: custom_document,
          },
          leaveType
        );
      } else {
        props.handleLeaveFromEmployee(
          {
            ...APPLY_LEAVE,
            ...leaveInfo,
            ...dummyData,
          },
          leaveType
        );
      }
      console.log(leaveInfo); // For debugging purposes, log the current leaveInfo state
      // Your form submission logic here
    }
  };

  const handleSelectLeaveType = (value) => {
    setSelectFullDate(value);
    switch (value) {
      case "1h":
        setLeaveInfo((prev) => ({
          ...baseLeaveInfo,
          ...APPLY_LEAVE_1_H,
          custom_1_hour_day: todayDate,
        }));
        break;
      case "2h":
        setLeaveInfo((prev) => ({
          ...baseLeaveInfo,
          ...APPLY_LEAVE_2_H,
          custom_2_hour_day: todayDate,
        }));
        break;
      default:
        setLeaveInfo((prev) => ({
          ...baseLeaveInfo,
          ...APPLY_LEAVE_DAYS,
          from_date: minDate,
          to_date: minDate,
        }));
        break;
    }
  };

  const renderDateSelection = () => {
    switch (selectFullDate) {
      case "1h":
        return (
          <div className="flex flex-col">
            <label className="self-start" htmlFor="fullDayLeaveFrom">
              Leave On
            </label>
            <input
              type="date"
              id="fullDayLeaveFrom"
              name="custom_1_hour_day"
              min={todayDate}
              value={leaveInfo.custom_1_hour_day}
              defaultValue={todayDate}
              onChange={handleChange}
              className="cursor-pointer"
            />
            {errors.from_date && (
              <span className="error color-[#ff9494]">{errors.from_date}</span>
            )}
          </div>
        );
      case "2h":
        return (
          <div className="flex flex-col">
            <label className="self-start" htmlFor="fullDayLeaveFrom">
              Leave On
            </label>
            <input
              type="date"
              id="fullDayLeaveFrom"
              name="custom_2_hour_day"
              value={leaveInfo.custom_2_hour_day}
              min={todayDate}
              defaultValue={todayDate}
              onChange={handleChange}
              className="cursor-pointer"
            />
            {errors.from_date && (
              <span className="error color-[#ff9494]">{errors.from_date}</span>
            )}
          </div>
        );
      default:
        return (
          <div className="flex flex-col md:flex-row mt-0 gap-2">
            <div className="flex  mt-0 flex-col">
              <label className={"self-start"}>Leave From:</label>
              <input
                type="date"
                id="fullDayLeaveFrom"
                name="from_date"
                min={minDate}
                value={leaveInfo.from_date}
                defaultValue={leaveInfo["from_date"]}
                onChange={handleChange}
                className="cursor-pointer"
              />
              {errors.from_date && (
                <span className="error color-[#ff9494]">
                  {errors.from_date}
                </span>
              )}
            </div>
            <div className="flex mt-0 flex-col">
              <label className={"self-start"}>Leave To:</label>
              <input
                type="date"
                id="fullDayLeaveTo"
                name="to_date"
                min={leaveInfo["from_date"]}
                value={leaveInfo.to_date}
                defaultValue={leaveInfo["to_date"]}
                onChange={handleChange}
                className="cursor-pointer"
              />
              {errors.to_date && (
                <span className="error color-[#ff9494]">{errors.to_date}</span>
              )}
            </div>
          </div>
        );
    }
  };

  const renderLeaveTitle = () => {
    switch (selectFullDate) {
      case "1h":
        return "1 hour leave";
      case "2h":
        return "2 hour leave";
      case "fullDay":
        return "Full day leave";
      default:
        return query.slice(1);
    }
  };
  const handleGifClick = () => {
    fileInputRef.current.click();
  };

  const handleCheckInCheckOut = () => {
    props.handleCheckClick(handleCheckValue(), props.latitude, props.longitude);
  };

  const handleCheckValue = () => {
    if (query.slice(1) === "check-in") {
      return "IN";
    } else if (query.slice(1) === "check-out") {
      return "OUT";
    }
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    setFileName(e.target.files[0].name);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img64 = DataURIToBlob(reader.result);
        setCustomDocument(img64);
        // data saved
      };
      reader.readAsDataURL(file);
    }
  };
  console.log(
    "manualEmpId==>",
    Boolean(props.manualEmpId),
    isDocument,
    customDocument
  );
  return (
    <>
      <Modal
        onClose={props.handleCloseModal}
        show={true}
        title={renderLeaveTitle()}
        isFooterVisible={Boolean(selectFullDate)}
        onClickFooter={applyLeave ? handleClickFooter : handleCheckInCheckOut}
        footerButtonName={"Submit"}
        isFooterDisabled={
          isDocument ? !Boolean(isDocument && customDocument) : false
        }
      >
        <h2 className="mb-0 font-bold text-[20px] text-[#360087] w-[180px]">
          User Details
        </h2>
        <span className="text-[#69707D] text-[14px]">
          {" "}
          Time: {getCurrentTime()}
        </span>{" "}
        <div className="flex w-full justify-between">
          <div>
            {!Boolean(props.manualEmpId) && (
              <div className="w-[160px] h-[170px] mt-1 Msm:h-[120px] Msm:w-[110px]">
                <img
                  src={props?.img}
                  alt={props?.data?.docs[0]?.first_name}
                  className="border-2 border-[#DADCE0] rounded-[5px] w-[100%] h-[100%]"
                />
              </div>
            )}
          </div>
          <div className="w-[50%]">
            <div className="mb-2">
              <p className="text-[#000A23] text-[18px] Msm:text-[14px] urbanist-font font-bold mb-[-6px]">
                {props?.data?.docs[0]?.employee_name}
              </p>
              <p className="text-[#343741] text-[18px] Msm:text-[14px] urbanist-font">
                {props?.data?.docs[0]?.designation}
              </p>
            </div>

            <div className="mb-2">
              <p className="text-[#4A7194] text-[14px] Msm:text-[12px] urbanist-font mb-[-6px]">
                Employee ID
              </p>
              <p className="text-[#343741] text-[18px] Msm:text-[14px] urbanist-font">
                {props?.data?.docs[0]?.name}
              </p>
            </div>
          </div>
          {selectFullDate && (
            <CrossIcon
              className="ml-4"
              size="small"
              handleCross={() => {
                setSelectFullDate("");
                setIsDocument(false);
                setFileName("");
                setCustomDocument(null);
                setLeaveInfo((prevInfo) => ({
                  ...prevInfo,
                  to_date: todayDate,
                  from_date: todayDate,
                }));
              }}
            />
          )}
        </div>
        <div className="item-center flex flex-col md:flex-row">
          {applyLeave ? (
            <div>
              {Boolean(selectFullDate) ? (
                <>
                  <div className="flex md:flex-row flex-col gap-[24px] self-center">
                    <div className="flex flex-col items-start">
                      <p className="self-start">Leave Type</p>
                      <div className="flex gap-[8px]">
                        <label
                          className={"cursor-pointer self-start"}
                          htmlFor="Casual Leave"
                        >
                          Casual
                        </label>
                        <input
                          id="Casual Leave"
                          type="radio"
                          name="leave_type"
                          value="Casual Leave"
                          checked={leaveInfo.leave_type === "Casual Leave"}
                          onChange={handleChange}
                          className="mr-[4px]"
                        />

                        <label
                          htmlFor="Sick Leave"
                          className={"cursor-pointer"}
                        >
                          Sick
                        </label>
                        <input
                          id="Sick Leave"
                          type="radio"
                          name="leave_type"
                          value="Sick Leave"
                          checked={leaveInfo.leave_type === "Sick Leave"}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.leave_type && (
                        <span className="error color-[#ff9494]">
                          {errors.leave_type}
                        </span>
                      )}
                    </div>
                    <div className="">{renderDateSelection()}</div>
                  </div>
                </>
              ) : (
                <div className="flex  ">
                  <button onClick={() => handleSelectLeaveType("1h")}>
                    1 hour early
                  </button>
                  <button onClick={() => handleSelectLeaveType("2h")}>
                    2 hour early
                  </button>
                  <button onClick={() => handleSelectLeaveType("fullDay")}>
                    Full Day
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col">
              {/* <button onClick={() => setApplyLeave((prev) => !prev)}>
                {applyLeave ? "Check-in-out" : "Apply Leave"}
              </button> */}
            </div>
            /** */
          )}
          {isDocument && (
            <div
              onClick={handleGifClick}
              className="upload-docs md:ml-4 p-2 border border-gray-300 flex flex-col rounded-md item-center justify-center"
            >
              <>
                <input
                  type="file"
                  id="docUpload"
                  name="document"
                  accept=".doc,.docx,.pdf,.jpg,.jpeg,.png"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={handleUploadImage}
                />

                <div className={`h-[50px] w-[50px] cursor-pointer`}>
                  <img
                    src={uploadGif}
                    alt="upload-doc"
                    className="h-full w-full"
                  />
                </div>

                <label htmlFor="docUpload" className="">
                  {fileName ? fileName : "Upload Document"}
                </label>
                <span className="color[#ff9494]">{errors.custom_document}</span>
              </>
            </div>
          )}
        </div>
        <div className="h-[64px] ">
          <h1 className="text-[#360087] text-[20px] urbanist-font font-bold">
            Work Areas
          </h1>
          <p className="text-[#007E77] text-[14px] urbanist-font">
            Your Current Location
          </p>
        </div>
        <div className="urbanist-font flex w-full justify-between Msm:flex-col Msm:gap-6 Msm:items-start">
          <div className="mr-4 Msm:mr-0">
            <p className="text-[#4A7194] text-[14px]">City/Town</p>
            <p className="text-[#343741] text-[18px]">
              {props?.data?.docs[0]?.custom_current_city}
            </p>
          </div>
          <div className="ml-[100px] Msm:ml-0">
            <div className="flex gap-3">
              <p className="text-[#4A7194] text-[14px] urbanist-font">
                Authorised Work Areas
              </p>
              <p
                onClick={handleRelocate}
                className="text-[#0077CC] text-[14px] urbanist-font cursor-pointer"
              >
                Relocate
              </p>
            </div>
            {loadingData && <Spinner />}
            {formattedAddress && <p>Address: {formattedAddress}</p>}
            {errorMessage && (
              <p style={{ color: "red" }}>Error: {errorMessage}</p>
            )}
            {!formattedAddress && !loadingData && props.memoizedAddress && (
              <p>{props.memoizedAddress}</p>
            )}
            {/* {props.locationLoading && <Spinner />}
            <p className="text-[#343741] text-[16px] urbanist-font">
              {props?.data?.docs[0]?.permanent_address}
            </p> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export { MarkEmployeeDetails };
