// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:12px;
  min-height: 100vh;
  background-color: #b3cdd1;
  background-image: linear-gradient(315deg, #b3cdd1 0%, #9fa4c4 74%);
}

h2 {
  margin-bottom: 20px;
  color: #333;
}



button {
  background: linear-gradient(to right, #90427a, #440f50);
  border: none;
  border-radius: 25px;
  color: #ffffff;
  padding: 10px 15px;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px #fff;
}


/* Responsive */
@media (max-width: 450px) {
  .input-box {
    width: 90%;
  }
  
}
  

.heading {
  color: #90427a;
  text-align: center;

}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  transform: scaleX(1.1);
}`, "",{"version":3,"sources":["webpack://./src/pages/signup/Signup.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;EACR,iBAAiB;EACjB,yBAAyB;EACzB,kEAAkE;AACpE;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;;;AAIA;EACE,uDAAuD;EACvD,YAAY;EACZ,mBAAmB;EACnB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;AAC3B;;;AAGA,eAAe;AACf;EACE;IACE,UAAU;EACZ;;AAEF;;;AAGA;EACE,cAAc;EACd,kBAAkB;;AAEpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["\n.signup-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap:12px;\n  min-height: 100vh;\n  background-color: #b3cdd1;\n  background-image: linear-gradient(315deg, #b3cdd1 0%, #9fa4c4 74%);\n}\n\nh2 {\n  margin-bottom: 20px;\n  color: #333;\n}\n\n\n\nbutton {\n  background: linear-gradient(to right, #90427a, #440f50);\n  border: none;\n  border-radius: 25px;\n  color: #ffffff;\n  padding: 10px 15px;\n  font-size: 16px;\n  margin: 10px;\n  cursor: pointer;\n  transition: all 0.3s ease;\n}\n\nbutton:hover {\n  transform: scale(1.1);\n  box-shadow: 0 0 10px #fff;\n}\n\n\n/* Responsive */\n@media (max-width: 450px) {\n  .input-box {\n    width: 90%;\n  }\n  \n}\n  \n\n.heading {\n  color: #90427a;\n  text-align: center;\n\n}\n\n.cursor-pointer {\n  cursor: pointer;\n}\n\n.cursor-pointer:hover {\n  transform: scaleX(1.1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
