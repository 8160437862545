// /* eslint-disable */
// import React, { useState, useRef, useEffect, useCallback } from "react";
// import * as tf from "@tensorflow/tfjs";
// import * as blazeface from "@tensorflow-models/blazeface";
// import Spinner from "../Spinner";
// import flipCameraIcon from "../../assets/flip-camera.svg";
// import "./styles.css";
// import { ConfirmationModal } from "../../components/ConfirmationModal";

// // Polyfill for getUserMedia for compatibility across browsers
// (function polyfillGetUserMedia() {
//   if (typeof window === "undefined") return;
//   if (navigator.mediaDevices === undefined) navigator.mediaDevices = {};
//   if (navigator.mediaDevices.getUserMedia === undefined) {
//     navigator.mediaDevices.getUserMedia = function (constraints) {
//       const getUserMedia =
//         navigator.getUserMedia ||
//         navigator.webkitGetUserMedia ||
//         navigator.mozGetUserMedia ||
//         navigator.msGetUserMedia;

//       if (!getUserMedia) {
//         return Promise.reject(
//           new Error("getUserMedia is not implemented in this browser")
//         );
//       }

//       return new Promise((resolve, reject) => {
//         getUserMedia.call(navigator, constraints, resolve, reject);
//       });
//     };
//   }
// })();

// const CAMERA_FACING_MODE = {
//   FRONT_FACING: "user",
//   BACK_CAMERA: "environment",
// };

// const cameraProfile = {
//   knownWidth: 0.15, // in meters, standardized face width
//   focalLength: 650, // average focal length in mm
// };

// const CameraComponent = ({
//   loading,
//   handleFaceRecognize,
//   showModal,
//   startCamera,
//   stopCamera,
//   height = "95vh",
// }) => {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const modelRef = useRef(null);
//   const liveViewRef = useRef(null);

//   const [capturedImage, setCapturedImage] = useState(null);
//   const [facingMode, setFacingMode] = useState(CAMERA_FACING_MODE.FRONT_FACING);
//   const [isMobile, setIsMobile] = useState(false);
//   const [isWeb, setIsWeb] = useState(false);
//   const [isDistanceCorrect, setIsDistanceCorrect] = useState(false);
//   const [showDistanceModal, setShowDistanceModal] = useState(false);
//   const [distanceModalMessage, setDistanceModalMessage] = useState("");

//   useEffect(() => {
//     if (!loading && !showModal) setCapturedImage(null);
//   }, [loading, showModal]);

//   useEffect(() => {
//     setIsMobile(checkIsMobile());
//     initializeBackend();
//   }, []);

//   useEffect(() => {
//     if (!videoRef?.current) return;
//     if (startCamera) enableCamera(facingMode);
//     return () => {
//       if (stopCamera) disableCamera();
//     };
//   }, [facingMode, startCamera, stopCamera]);

//   const checkIsMobile = () => {
//     let check = false;
//     (function (a) {
//       if (
//         /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge|maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.browser|up\.link|vodafone|wap|windows ce|xda|xiino/i.test(
//           a
//         ) ||
//         /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r|s)|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|compal|craw|da(it|ll|ng)|dbte|dc|devi|dica|dmob|docomo|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly|g1 u|g560|gene|gf|gmo|good|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp(ip)|hs(c|t)|ht(c|g|p|s|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( |\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|va)|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt|kwc|kyo(c|k)|leno|lexi|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1|m3ga|m50\/|mate|mc(01|21|ca)|m(cr|de|do|t|zz)|mt(50|p1|v)|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qa(a|c)|qc(07|12|21|32|60|-2|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk|se(c(0|1)|47|mc|nd|ri)|sgh|shar|sie(m)|sk(0)|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v)|sy(01|mb)|t2(18|50)|t6(00|10|18)|tagt|talk|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|50|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c|webc|whit|wi(g|nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
//           a.substr(0, 4)
//         )
//       )
//         check = true;
//     })(navigator.userAgent || navigator.vendor || window.opera);
//     return check;
//   };

//   const enableCamera = useCallback(async (facingModes) => {
//     try {
//       const constraints = {
//         video: {
//           facingMode:
//             facingModes === CAMERA_FACING_MODE.BACK_CAMERA
//               ? { exact: "environment" }
//               : "user",
//         },
//       };
//       const stream = await navigator.mediaDevices.getUserMedia(constraints);
//       videoRef.current.srcObject = stream;
//       videoRef.current.onloadeddata = () => detectFaces(); // Start detection when video is ready
//     } catch (err) {
//       setFacingMode(CAMERA_FACING_MODE.FRONT_FACING);
//       console.error(err);
//     }
//   }, []);

//   const disableCamera = () => {
//     if (videoRef.current) {
//       videoRef.current.pause();
//       if (videoRef.current.srcObject) {
//         videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
//       }
//       videoRef.current.srcObject = null;
//     }
//   };

//   const initializeBackend = useCallback(async () => {
//     try {
//       await tf.setBackend("webgl");
//       await tf.ready();
//       modelRef.current = await blazeface.load();
//     } catch (error) {
//       console.error("WebGL backend not supported, falling back to CPU:", error);
//       await tf.setBackend("cpu");
//       await tf.ready();
//       modelRef.current = await blazeface.load();
//     }
//   }, []);

//   // Modified detectFaces for continuous detection using requestAnimationFrame
//   const detectFaces = async () => {
//     if (!modelRef.current || !videoRef.current) return;
//     const video = videoRef.current;

//     // Ensure video is loaded with valid dimensions
//     if (!video || video.videoWidth === 0 || video.videoHeight === 0) {
//       requestAnimationFrame(detectFaces);
//       return;
//     }

//     try {
//       // Detect faces using the model
//       const predictions = await modelRef.current.estimateFaces(video, false);

//       if (predictions.length > 0) {
//         const pred = predictions[0];
//         const width = pred.bottomRight[0] - pred.topLeft[0];
//         const calculatedDistance =
//           (cameraProfile.knownWidth * cameraProfile.focalLength) / width;

//         if (calculatedDistance >= 0.25 && calculatedDistance <= 0.6) {
//           setIsDistanceCorrect(true);
//           setShowDistanceModal(false);
//         } else {
//           setIsDistanceCorrect(false);
//           setDistanceModalMessage(
//             calculatedDistance < 0.25
//               ? "You are too close to the camera."
//               : "You are too far from the camera."
//           );
//           setShowDistanceModal(true);
//         }
//       } else {
//         setIsDistanceCorrect(false);
//         setDistanceModalMessage("No face detected.");
//         setShowDistanceModal(true);
//       }
//     } catch (error) {
//       console.error("Error during face detection:", error);
//     }

//     requestAnimationFrame(detectFaces);
//   };

//   const captureImage = async () => {
//     if (!isDistanceCorrect || !videoRef.current || !canvasRef.current) return;
//     const video = videoRef.current;
//     const canvas = canvasRef.current;
//     canvas.width = video.videoWidth;
//     canvas.height = video.videoHeight;
//     const context = canvas.getContext("2d");

//     context.save();
//     if (facingMode === CAMERA_FACING_MODE.FRONT_FACING) {
//       context.translate(canvas.width, 0);
//       context.scale(-1, 1);
//     }
//     context.drawImage(video, 0, 0, canvas.width, canvas.height);
//     context.restore();

//     const imageData = canvas.toDataURL("image/png");
//     setCapturedImage(imageData);
//     await handleFaceRecognize(imageData);
//   };

//   const handleRotateCamera = () => {
//     setFacingMode((prev) =>
//       prev === CAMERA_FACING_MODE.FRONT_FACING
//         ? CAMERA_FACING_MODE.BACK_CAMERA
//         : CAMERA_FACING_MODE.FRONT_FACING
//     );
//   };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         setCapturedImage(reader.result);
//         handleFaceRecognize(reader.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   return (
//     <div className="camera-wrapper">
//       {loading && <Spinner />}
//       <div
//         className="camera-container"
//         style={{ height: `${height ?? "  95dvh"}` }}
//       >
//         <video
//           id="video"
//           className={`video-player  video-player-front ${
//             facingMode === CAMERA_FACING_MODE.RIGHT && "video-player-front"
//           }`}
//           ref={videoRef}
//           autoPlay
//           playsInline
//         />
//         <div className="liveView" ref={liveViewRef}></div>
//         <canvas
//           ref={canvasRef}
//           style={{ display: "none", width: "auto", height: "auto" }}
//         />
//         <>
//           <div className="video-action-btn"></div>
//           <div
//             onClick={captureImage}
//             className="action-btn-wrapper"
//             disabled={loading || !videoRef.current}
//           >
//             <div className="action-btn">
//               {capturedImage ? <div className="reloadDouble" /> : <></>}
//             </div>
//           </div>
//           {!isWeb && isMobile && (
//             <div className="switch-camera-button" onClick={handleRotateCamera}>
//               <img
//                 className="switch-camera-icon"
//                 src={flipCameraIcon}
//                 alt="flip-camera"
//               />
//             </div>
//           )}
//         </>
//         <input
//           type="file"
//           name="video"
//           accept="video/*"
//           capture={facingMode}
//           onChange={handleFileChange}
//           style={{ display: "none" }}
//         />
//       </div>

//       {showDistanceModal && (
//         <ConfirmationModal
//           open={showDistanceModal}
//           title="Distance Warning"
//           message={distanceModalMessage}
//           handleCloseModal={() => setShowDistanceModal(false)}
//         />
//       )}
//     </div>
//   );
// };

// export default CameraComponent;

/* eslint no-use-before-define: 0 */ // --> OFF
import React, { useState, useRef, useEffect } from "react";
import Spinner from "../Spinner";
import flipCameraIcon from "../../assets/flip-camera.svg";
import "./styles.css";

// Polyfill for getUserMedia to ensure compatibility across different browsers
(function polyfillGetUserMedia() {
  if (typeof window === "undefined") {
    return;
  }

  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      const getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;

      if (!getUserMedia) {
        return Promise.reject(
          new Error("getUserMedia is not implemented in this browser")
        );
      }

      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  }
})();

const CAMERA_FACING_MODE = {
  FRONT_FACING: "user",
  BACK_CAMERA: "environment",
};

const CameraComponent = ({
  loading,
  handleFaceRecognize,
  showModal,
  height,
}) => {
  const videoRef = useRef(null);
  const liveViewRef = useRef(null);
  const canvasRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [facingMode, setFacingMode] = useState(CAMERA_FACING_MODE.FRONT_FACING);
  const [isWeb, setIsWeb] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (!loading && !showModal) {
      setCapturedImage(null);
      requestAnimationFrame(predictWebcam);
    }
  }, [loading, showModal]);
  useEffect(() => {
    const isMobileCheck = checkIsMobile();
    setIsMobile(isMobileCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const checkIsMobile = () => {
  //   let check = false;
  //   (function (a) {
  //     if (
  //       /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
  //         a
  //       ) ||
  //       /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
  //         a.substr(0, 4)
  //       )
  //     )
  //       check = true;
  //   })(navigator.userAgent || navigator.vendor || window.opera);
  //   return check;
  // };

  const checkIsMobile = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge|maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.browser|up\.link|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r|s)|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|compal|craw|da(it|ll|ng)|dbte|dc|devi|dica|dmob|docomo|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly|g1 u|g560|gene|gf|gmo|good|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp(ip)|hs(c|t)|ht(c|g|p|s|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( |\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|va)|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt|kwc|kyo(c|k)|leno|lexi|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1|m3ga|m50\/|mate|mc(01|21|ca)|m(cr|de|do|t|zz)|mt(50|p1|v)|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qa(a|c)|qc(07|12|21|32|60|-2|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk|se(c(0|1)|47|mc|nd|ri)|sgh|shar|sie(m)|sk(0)|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v)|sy(01|mb)|t2(18|50)|t6(00|10|18)|tagt|talk|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|50|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c|webc|whit|wi(g|nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const enableCamera = async (facingModes) => {
    try {
      const constraints = {
        video: {
          facingMode:
            facingModes === CAMERA_FACING_MODE.BACK_CAMERA
              ? { exact: "environment" }
              : "user",
        },
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
      videoRef.current.addEventListener("loadeddata", predictWebcam);
    } catch (err) {
      setFacingMode(CAMERA_FACING_MODE.FRONT_FACING);
      setIsWeb(true);
      console.error(err);
    }
  };

  useEffect(() => {
    if (!videoRef?.current) return;

    enableCamera(facingMode);

    const currentVideoRef = videoRef.current;
    return () => {
      if (currentVideoRef) {
        currentVideoRef.pause();
        if (currentVideoRef.srcObject) {
          currentVideoRef.srcObject.getTracks().forEach((track) => {
            track.stop();
            track.enabled = false; // This line is extra, may not be necessary
          });
        }
        currentVideoRef.srcObject = null;
        currentVideoRef.removeEventListener("loadeddata", predictWebcam);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facingMode]);

  function predictWebcam() {
    // Prediction logic here if needed
  }

  const handleRotateCamera = () => {
    setFacingMode((prev) =>
      prev === CAMERA_FACING_MODE.FRONT_FACING
        ? CAMERA_FACING_MODE.BACK_CAMERA
        : CAMERA_FACING_MODE.FRONT_FACING
    );
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCapturedImage(reader.result);
        handleFaceRecognize(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const captureImage = async () => {
    if (!videoRef.current || !canvasRef.current) return;
    const video = videoRef.current;
    const canvas = canvasRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = canvas.toDataURL("image/png");
    setCapturedImage(imageData);
    handleFaceRecognize(imageData);
  };

  return (
    <div className="camera-wrapper">
      {loading && <Spinner />}
      <div
        className="camera-container"
        style={{ height: `${height ?? "  95dvh"}` }}
      >
        <video
          id="video"
          className={`video-player  video-player-front ${
            facingMode === CAMERA_FACING_MODE.RIGHT && "video-player-front"
          }`}
          ref={videoRef}
          autoPlay
          playsInline
        />
        <div className="liveView" ref={liveViewRef}></div>
        <canvas
          ref={canvasRef}
          style={{ display: "none", width: "auto", height: "auto" }}
        />
        <>
          <div className="video-action-btn"></div>
          <div
            onClick={captureImage}
            className="action-btn-wrapper"
            disabled={loading || !videoRef.current}
          >
            <div className="action-btn">
              {capturedImage ? <div className="reloadDouble" /> : <></>}
            </div>
          </div>
          {!isWeb && isMobile && (
            <div className="switch-camera-button" onClick={handleRotateCamera}>
              <img
                className="switch-camera-icon"
                src={flipCameraIcon}
                alt="flip-camera"
              />
            </div>
          )}
        </>
        <input
          type="file"
          name="video"
          accept="video/*"
          capture={facingMode}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
};

export default CameraComponent;
